<template>
    <PageLayout>
        <template v-slot:side>
            <v-icon disabled color="secondary" @click="filter_show === true ? filter_show = false : filter_show = true" class="mb-4">mdi-filter-plus</v-icon>

            <v-card class="mb-4">
                <v-date-picker
                    :first-day-of-week="1"
                    class="my_date_picker ma-3"
                    no-title
                    v-model="date_left_calendar"
                    :events="arrayEvents"
                    :event-color=" date => (date >= currentDate ? 'primary' : 'red')"
                    @change="setDateLeftCalendar"
                    :picker-date.sync="pickerDate"
                ></v-date-picker>
            </v-card>

            <v-list class="filter_left mb-4">
                <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{$t('case_type')}}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in caseTypeFilters" :key="i" link :class="item.id ===  case_type_filter ? 'active' : ''">
                        <v-list-item-title v-text="item.name" class="cursor-pointer" @click="sendRoute(item)"></v-list-item-title>
                        <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list class="filter_left" v-if="Additional.length > 0">
                <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{$t('additional')}}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
        <template>
            <v-form @submit.prevent="getCaseAdmin('search')">
                <v-btn v-show="$vuetify.breakpoint.xsOnly" :disabled="userBlock" @click="dialogCase=true" color="secondary" fab fixed bottom right class="v-btn--add-form-top white--text">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <div class="tabs-nav">
                        <div class="tabs-nav__inner">
                            <div class="tabs-nav__item" :class="{'active': type_calendar === 'day'}">
                                <button @click="type_calendar = 'day'">{{$t('day')}}</button>
                            </div>
                            <div class="tabs-nav__item" :class="{'active': type_calendar === 'week'}">
                                <button @click="type_calendar = 'week'">{{$t('week')}}</button>
                            </div>
                            <div class="tabs-nav__item" :class="{'active': type_calendar === 'month'}">
                                <button @click="type_calendar = 'month'">{{$t('month')}}</button>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-space-between mb-10">
                        <div class="d-flex align-center">
                            <h1>{{ filter_show ? $t('search_calendar') : $t('calendar') }}</h1>

                        </div>
                        <div class="text-right">
                            <Btn :disabled="userBlock" @click="dialogCase=true" color="secondary">
                                {{ $t('create') }}
                            </Btn>
                        </div>
                    </div>


                    <v-card flat class="background_color_transparent">
                        <v-card-text class="py-0">
                            <v-row v-if="filter_show">
                                <v-col class="py-0" cols="12" sm="6">
                                    <v-dialog ref="dateDialog" v-model="dateDialog" width="290px">
                                        <v-date-picker ref="pickerDateContract" v-model="date" :locale="lang" @change="dateDialog = false"></v-date-picker>
                                    </v-dialog>
                                    <ValidationProvider ref="dateContract" rules="date_format"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="date" :error-messages="errors"
                                                      :error="!valid"
                                                      :label="$t('date_created')"
                                                      readonly
                                                      @click.stop="dateDialog = !dateDialog"
                                                      @click:clear="date = null"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable

                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="invoice_status" rules="" v-slot="{ errors, valid }">
                                        <v-select v-model="invoice_status"
                                                  :items="invoice_statusItems" :error-messages="errors"
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  :label="$t('invoice_status')"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  :disabled="loading"
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                  clearable
                                        ></v-select>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions v-if="filter_show"  class="px-4 py-4">
                            <v-row>
                                <v-col cols="12" sm="3" md="2">
                                    <v-select :value="perPage" :items="perPageItems"
                                              :label="$t('items_per_page')"
                                              @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :disabled="loading"
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"

                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="9" md="10"
                                       class="d-flex justify-center justify-sm-end align-self-center">
                                    <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                           color="primary" class="infinity_button">{{ $t('search') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                        <v-calendar
                            ref="calendar"
                            :type="type_calendar"
                            event-overlap-mode="stack"
                            v-model="to_do_calendar"
                            full-width
                            interval-width="100"
                            @input="setCalendarEvents"
                            :events="calendarEvents"
                            :weekdays="weekday"
                            @contextmenu:date="showContextMenu"
                            @contextmenu:time="showContextMenu"
                            @click:event="showEvent"
                            style="min-height: 500px"
                        >
                        </v-calendar>
                        <v-card v-if="filteredEvents.length > 0">
                            <v-card-title>{{ filteredEvents[0].date_text }}</v-card-title>
                            <v-card-text>
                                <ul>
                                    <li v-for="event in filteredEvents" :key="event.uuid" class="pb-4">
                                        <strong>
                                            <v-btn :disabled="userBlock" text plain :to="'/case/' + event.uuid + '/show'" class="cursor-pointer font_weight_600 button_link">
                                                {{event.name}} ({{ event.title }})
                                            </v-btn>
                                        </strong>
                                        <div>
                                            {{ event.description }}
                                            <div v-if="event.uved && event.uved.uuid">
                                                <v-btn text plain :to="'/uved/' + event.uved.uuid + '/show'"  class="cursor-pointer button_link"  >
                                                    {{ event.uved.name }}
                                                </v-btn>
                                                <template v-if="event.uved.phones && event.uved.phones.length > 0">
                                                    <div v-for="(phone, index) in event.uved.phones" :key="'phone_'+index">
                                                        {{formatPhoneNumber(phone.phone)}}
                                                        <span>{{phone.field && phone.field !== 'null' ? phone.field : ''}}</span>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                            </v-card-text>
                        </v-card>
                    </v-card>
                </ValidationObserver>
            </v-form>
            <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <div>
                    <div>
                        <div>
                        </div>
                    </div>
                    <div class="my-2 px-1">
                        <v-menu
                            v-model="showMenu"
                            :position-x="menuX"
                            :position-y="menuY"
                            offset-x
                            absolute
                            :disabled="userBlock"
                        >
                            <v-list>
                                <v-list-item v-if="!($moment().unix() > $moment(date_case).unix()) || ($moment().format('DD.MM.YYYY') === $moment(date_case).format('DD.MM.YYYY'))" @click="createEvent(true)">{{$t('add_case')}}</v-list-item>
                                <v-list-item v-if="false" @click="deleteEvent">{{$t('delete')}}</v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </ValidationObserver>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="900"
                v-model="dialogCase"
                class="dialog_body"
                persistent
            >
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ edit_case ? $t('edit_case') : $t('add_case') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeCaseDialog()" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text class="pa-2 pb-0">
                            <v-col cols="12">
                                <v-row class="py-0">
                                    <v-col cols="12" sm="12" class="py-0">
                                        <ValidationProvider ref="case_type" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('case_type')}}</div>
                                            <v-select v-model="case_type" :disabled="loading || result"
                                                      :items="caseTypeItems" :error-messages="errors"
                                                      :error="!valid"
                                                      item-text="name" item-value="id"
                                                      :label="$t('case_type')" clearable
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      hide-details
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      append-outer-icon="mdi-plus"
                                                      @click:append-outer="dialogFormCaseType=true;case_type_name=null"
                                            ></v-select>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="12" class="py-0">
                                        <ValidationProvider ref="name" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('name')}}</div>
                                            <v-text-field v-model="name" type="text" :disabled="loading"
                                                          :error="!valid" :error-messages="errors"
                                                          :label="$t('name')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          hide-details
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="12" class="py-0">
                                        <ValidationProvider ref="case_text" rules="max:62000"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('description')}}</div>
                                            <v-textarea

                                                v-model="case_text"
                                                type="text"
                                                rows="1"
                                                :label="$t('description')"
                                                :disabled="loading || result"
                                                color="primary"
                                                auto-grow
                                                clearable outlined
                                                hide-details
                                                full-width
                                                background-color="white lighten-2"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                                            ></v-textarea>
                                        </ValidationProvider>
                                    </v-col>


                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="responsible" rules="required|min:1" v-slot="{ errors, valid }">
                                            <UserSelection
                                                :key="updateKey"
                                                v-model="responsible"
                                                id="responsible"
                                                :label="$t('responsible')"
                                                :error="!valid"
                                                :valid="valid"
                                                :error-messages="errors"
                                                :disabled="loading"
                                                clearable
                                            />
                                        </ValidationProvider>

                                    </v-col>

                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="participant" rules="min:1" v-slot="{ errors, valid }">
                                            <UserSelection
                                                :key="updateKey"
                                                multiple
                                                v-model="participant"
                                                :valuel="participant"
                                                id="participant"
                                                :label="$t('participants')"
                                                :error="!valid"
                                                :valid="valid"
                                                :error-messages="errors"
                                                :disabled="loading"
                                                clearable
                                            />
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="4" class="py-0">
                                        <v-dialog ref="dateDialogCase" v-model="dateDialogCase" width="290px">
                                            <v-date-picker ref="pickerDateContract" v-model="date_case"
                                                           first-day-of-week="1" :locale="lang"
                                                           :min="$moment().format('YYYY-MM-DD')"
                                                           @change="dateDialogCase = false;updateDisableStatus();">
                                            </v-date-picker>
                                        </v-dialog>
                                        <ValidationProvider ref="dateContract" rules="required|date_format"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('date_case')}}</div>
                                            <v-text-field v-model="date_case" :error-messages="errors"
                                                          :error="!valid" :disabled="loading || result"
                                                          :label="$t('date_case')"
                                                          readonly
                                                          @click.stop="dateDialogCase = !dateDialogCase"
                                                          clearable @click:clear="date_case = null"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          hide-details
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="4" class="py-0">
                                        <v-menu v-model="TimeFromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="time_from" name="time_from" rules="required|min:4|max:5" v-slot="{ errors, valid }">
                                                    <div class="input_label">{{$t('time_from')}}</div>
                                                    <v-text-field v-model="time_from" :error-messages="errors"
                                                                  :error="!valid"
                                                                  :disabled="loading" :label="$t('time_from')"
                                                                  color="primary" readonly v-on="on"

                                                                  clearable @click:clear="time_from = null"

                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  hide-details
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"

                                                    ></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-time-picker ref="picker" v-model="time_from"
                                                           format="24hr"
                                                           :max="time_to ? time_to : ''"
                                                           :locale="lang" @change="TimeFromMenu = false;updateDisableStatus();"></v-time-picker>
                                        </v-menu>


                                    </v-col>

                                    <v-col cols="12" sm="4" class="py-0">
                                        <v-menu v-model="TimeToMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="time_to" name="time_to" rules="min:4|max:5" v-slot="{ errors, valid }">
                                                    <div class="input_label">{{$t('time_to')}}</div>
                                                    <v-text-field v-model="time_to" :error-messages="errors" :error="!valid"
                                                                  :disabled="loading" :label="$t('time_to')"
                                                                  color="primary" readonly v-on="on"
                                                                  clearable
                                                                  @click:clear="time_to = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  hide-details
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"  >

                                                    </v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-time-picker ref="time_to" v-model="time_to"
                                                           format="24hr"
                                                           :min="time_from ? time_from : ''"
                                                           :locale="lang"
                                                           @change="TimeToMenu = false">

                                            </v-time-picker>
                                        </v-menu>

                                    </v-col>

                                    <v-col cols="12" sm="12" class="py-0">
                                        <ValidationProvider ref="location_event" rules="required|min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('location_event')}}</div>
                                            <v-select v-model="location_event" :disabled="loading || result"
                                                      :items="locationEventItems" :error-messages="errors"
                                                      :error="!valid"
                                                      item-text="name" item-value="id"
                                                      :label="$t('location_event')" clearable
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      hide-details
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      append-outer-icon="mdi-plus"
                                                      @click:append-outer="dialogFormLocationEvent=true;location_event_name=null"
                                            ></v-select>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col  cols="12" sm="12">
                                        <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-width="200"
                                            offset-y
                                            transition="scale-transition"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="primary"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    {{$t('add_reminder')}}
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item

                                                    v-for="(item, index) in reminderItems"
                                                    :key="index"
                                                    :disabled="item.disable"
                                                    @click="selectItem(item)"
                                                >
                                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>


                                        <v-list-item
                                            v-for="item in selectedReminderItems"
                                            :key="item.name"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title v-text="$t('reminder_to_title', {'time': item.name})"></v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-btn icon :title="$t('delete')">
                                                    <v-icon color="grey lighten-1" @click="deleteItem(item)">mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>

                                    </v-col>




                                    <v-col  cols="12" sm="12" class="py-0">
                                        <ValidationProvider ref="uved" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('uved.self')}}</div>
                                            <v-autocomplete v-model="uved" :items="uvedItems"
                                                            :error-messages="errors" :error="!valid"
                                                            :search-input.sync="uvedSearching"
                                                            item-text="name" item-value="id"
                                                            :loading="loadingUveds" :disabled="loading || result"
                                                            :no-data-text="uvedSearching ? $t('nothing_found_by',{'search': uvedSearching}) : $t('nothing_found_name')"
                                                            :label="$t('uved.self')"
                                                            @click:clear="uvedItems=[]"
                                                            @click="clearUveds"
                                                            clearable autocomplete="off"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            hide-details
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_text">
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty"
                                                              v-text="item.bin_iin"></span>
                                                    </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty"
                                                              v-text="item.bin_iin"></span>
                                                    </span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col  cols="12" sm="12" class="py-0">
                                        <ValidationProvider ref="deal" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('deal.self')}}</div>
                                            <v-autocomplete v-model="deal" :items="dealItems"
                                                            :error-messages="errors" :error="!valid"
                                                            :search-input.sync="dealSearching"
                                                            item-text="number" item-value="id"
                                                            :loading="loadingDeals" :disabled="loading || result"
                                                            :no-data-text="dealSearching ? $t('nothing_found_by',{'search': dealSearching}) : $t('nothing_found_name')"
                                                            :label="$t('deal.self')"
                                                            @click:clear="dealItems=[]"
                                                            @click="clearDeals"
                                                            clearable autocomplete="off"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            hide-details
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_text">
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin.name"></span>
                                            </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin.name"></span>
                                            </span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col  cols="12" sm="12" class="py-0">
                                        <ValidationProvider ref="task" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('task.self')}}</div>
                                            <v-autocomplete v-model="task" :items="taskItems"
                                                            :error-messages="errors" :error="!valid"
                                                            :search-input.sync="taskSearching"
                                                            item-text="number" item-value="id"
                                                            :loading="loadingTasks" :disabled="loading || result"
                                                            :no-data-text="taskSearching ? $t('nothing_found_by',{'search': taskSearching}) : $t('nothing_found_name')"
                                                            :label="$t('task.self')"
                                                            @click:clear="taskItems=[]"
                                                            @click="clearTasks"
                                                            clearable autocomplete="off"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            hide-details
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_text">
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin.name"></span>
                                            </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin.name"></span>
                                            </span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>





                                </v-row>
                                <v-row  v-if="!result && edit_case && ($moment().unix() >= $moment(date_case).unix())">
                                    <v-col class="p5-0" cols="12">
                                        <v-btn
                                            class="button_link"
                                            plain
                                            text
                                            :disabled="userBlock || invalid || loading" :loading="loading"
                                            @click="formResult"
                                            color="green"
                                        >{{ $t('complete') }}</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row  v-if="result">
                                    <v-col cols="12" sm="12">
                                        <ValidationProvider ref="case_status" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="case_status" :disabled="loading"
                                                      :items="caseStatusItems" :error-messages="errors"
                                                      :error="!valid"
                                                      item-text="name" item-value="id"
                                                      :label="$t('case_status')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      hide-details
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" v-if="false">
                                        <ValidationProvider ref="funnel_status" rules=""
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="funnel_status" :disabled="loading"
                                                      :items="funnelStatusItems" :error-messages="errors"
                                                      :error="!valid"
                                                      item-text="name" item-value="id"
                                                      :label="$t('funnel_status')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      hide-details
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-textarea
                                            class="my-0 py-0"
                                            v-model="case_text_result"
                                            type="text"
                                            rows="1"
                                            auto-grow
                                            :label="$t('comment')"
                                            :disabled="loading"
                                            outlined
                                            row-height="25"
                                            no-details
                                            hide-details
                                            full-width
                                        >
                                            <template v-slot:append-outer>
                                                <v-icon @click="document.getElementById('case_file').click()">mdi-paperclip</v-icon>
                                            </template>

                                        </v-textarea>

                                        <v-file-input hide-input prepend-icon=""
                                                      v-model="case_files"
                                                      id="case_file"
                                                      multiple
                                                      @change="handleFileUpload"
                                        >
                                        </v-file-input>

                                    </v-col>

                                    <v-col
                                        v-for="(item, index) in selectedFiles"
                                        :key="index"
                                        cols="6"
                                    >
                                        <v-card>
                                            <v-card-text>
                                                <v-icon
                                                    @click="deleteCaseFile(item)"
                                                    color="error"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                                {{ item.name }}
                                                <v-spacer></v-spacer>

                                            </v-card-text>

                                        </v-card>
                                    </v-col>




                                </v-row>
                            </v-col>
                        </v-card-text>

                        <v-card-actions class="justify-end" v-if="!result">
                            <v-btn
                                text
                                :disabled="userBlock || invalid || loading" :loading="loading"
                                @click="addCaseAdmin"
                            >{{ $t('save') }}</v-btn>
                        </v-card-actions>
                        <v-card-actions class="justify-end" v-if="result">
                            <v-btn
                                text
                                @click="result = false"
                            >{{ $t('cancel') }}</v-btn>
                            <v-btn
                                text
                                :disabled="userBlock || invalid || loading" :loading="loading"
                                @click="addCaseAdmin"
                                color="green"
                            >{{ $t('complete') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogCaseResult"
                class="dialog_body"
            >
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{$t('case') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeCaseDialog()" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text class="pa-2 pb-0">
                            <v-col cols="12">
                                <v-row class="py-0">
                                    <v-col cols="12" sm="12">
                                        <ValidationProvider ref="case_type" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="case_type" :disabled="true"
                                                      :items="caseTypeItems" :error-messages="errors"
                                                      :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      :label="$t('case_type')" clearable
                                                      hide-details
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"

                                            ></v-select>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-dialog ref="dateDialogCase" v-model="dateDialogCase" width="290px">
                                            <v-date-picker ref="pickerDateContract" v-model="date_case"
                                                           first-day-of-week="1" :locale="lang"
                                                           @change="dateDialogCase = false">
                                            </v-date-picker>
                                        </v-dialog>
                                        <ValidationProvider ref="dateContract" rules="required|date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="date_case" :error-messages="errors"
                                                          :error="!valid" :disabled="true"
                                                          :label="$t('date_case')"
                                                          color="primary" readonly
                                                          @click.stop="dateDialogCase = !dateDialogCase"
                                                          clearable @click:clear="date_case = null"
                                                          hide-details
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <template >
                                            <ValidationProvider ref="on_time" name="on_time" rules="min:4|max:5" v-slot="{ errors, valid }">
                                                <v-text-field v-model="on_time" :error-messages="errors"
                                                              :disabled="true" :label="$t('on_time')"
                                                              color="primary"
                                                              clearable @click:clear="on_time = null"
                                                              hide-details
                                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </template>


                                    </v-col>

                                    <v-col cols="12" sm="12" v-if="uved">
                                        <ValidationProvider ref="uved" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="uved" :items="uvedItems"
                                                            :error-messages="errors" :error="!valid"
                                                            :search-input.sync="uvedSearching"
                                                            item-text="name" item-value="id"
                                                            :loading="loadingUveds" :disabled="true"
                                                            :no-data-text="uvedSearching ? $t('nothing_found_by',{'search': uvedSearching}) : $t('nothing_found_name')"
                                                            :label="$t('uved.self')"
                                                            @click:clear="uvedItems=[]"
                                                            @click="clearUveds"
                                                            color="primary" clearable autocomplete="off"
                                                            hide-details
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                            >
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty"
                                                              v-text="item.bin_iin"></span>
                                                    </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty"
                                                              v-text="item.bin_iin"></span>
                                                    </span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="12" v-if="deal">
                                        <ValidationProvider ref="deal" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="deal" :items="dealItems"
                                                            :error-messages="errors" :error="!valid"
                                                            :search-input.sync="dealSearching"
                                                            item-text="number" item-value="id"
                                                            :loading="loadingDeals" :disabled="true"
                                                            :no-data-text="dealSearching ? $t('nothing_found_by',{'search': dealSearching}) : $t('nothing_found_name')"
                                                            :label="$t('deal.self')"
                                                            @click:clear="dealItems=[]"
                                                            @click="clearDeals"
                                                            color="primary" clearable autocomplete="off"
                                                            hide-details
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                            >
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin"></span>
                                            </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin"></span>
                                            </span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="12" v-if="task">
                                        <ValidationProvider ref="task" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="task" :items="taskItems"
                                                            :error-messages="errors" :error="!valid"
                                                            :search-input.sync="taskSearching"
                                                            item-text="number" item-value="id"
                                                            :disabled="true"
                                                            :loading="loadingTasks"
                                                            :no-data-text="taskSearching ? $t('nothing_found_by',{'search': taskSearching}) : $t('nothing_found_name')"
                                                            :label="$t('task.self')"
                                                            @click:clear="taskItems=[]"
                                                            @click="clearTasks"
                                                            color="primary" clearable autocomplete="off"
                                                            hide-details
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                            >
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin.name"></span>
                                            </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin.name"></span>
                                            </span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col sm="12" v-if="case_text">
                                        <v-textarea
                                            class="my-0 py-0"
                                            v-model="case_text"
                                            type="text"
                                            rows="1"
                                            auto-grow
                                            :label="$t('description')"
                                            :disabled="true"
                                            hide-details
                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"

                                        ></v-textarea>
                                    </v-col>

                                    <v-col cols="12" sm="12">
                                        <ValidationProvider ref="case_status" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="case_status" :disabled="true"
                                                      :items="caseStatusItems" :error-messages="errors"
                                                      :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      :label="$t('case_status')"
                                                      hide-details
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                            ></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" v-if="case_text_result">
                                        <v-textarea
                                            v-model="case_text_result"
                                            type="text"
                                            rows="1"
                                            auto-grow

                                            :label="$t('comment')"
                                            :disabled="true"
                                            hide-details
                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"

                                        >


                                        </v-textarea>




                                    </v-col>

                                    <v-col
                                        v-for="(item, index) in selectedFiles"
                                        :key="index"
                                        cols="6"
                                    >
                                        <v-card @click="downloadFile(item)">
                                            <v-card-text>
                                                {{ item.name }}
                                                <v-spacer></v-spacer>

                                            </v-card-text>

                                        </v-card>
                                    </v-col>

                                </v-row>
                            </v-col>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                @click="dialogCaseResult = false"
                            >{{ $t('close') }}</v-btn>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-dialog>

            <v-dialog
                persistent
                max-width="1200px"
                transition="dialog-bottom-transition"
                v-model="dialogFormLocationEvent"
            >
                <v-form @submit.prevent="saveLocationEvent">
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                        <v-card flat >
                            <v-card-text class="py-0">
                                <v-row>
                                    <v-col cols="12">
                                        <v-row class="d-flex mt-5">
                                            <v-col cols="10" class="ma-auto">
                                                <span class="title_menu">{{ $t('location_event') }}</span>
                                            </v-col>
                                            <v-col cols="2" class="d-flex justify-end">
                                                <v-icon @click="closeLocationEvent" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-2">
                                    <v-col class="py-0" cols="12">
                                        <v-tabs v-model="tab" background-color="transparent"
                                                :right="$vuetify.breakpoint.mdAndUp"
                                                :grow="$vuetify.breakpoint.smAndDown"
                                                color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                            <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                            </v-tab>
                                        </v-tabs>
                                    </v-col>
                                </v-row>

                                <v-row class="mt-5">
                                    <v-col class="py-0" cols="12" >
                                        <ValidationProvider ref="location_event_name" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="location_event_name" type="text" :error="!valid" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('location_event')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable>>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>


                            </v-card-text>

                            <v-card-actions class="px-4 py-7">
                                <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                    {{ $t('save') }}
                                </v-btn>
                                <v-btn
                                    :disabled="loading"
                                    class="ma-1 button_cancel"
                                    plain
                                    @click="closeLocationEvent()"
                                >
                                    {{$t('cancel')}}
                                </v-btn>
                                <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                                <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                                   :active="loading" class="mx-2"></v-progress-linear>

                            </v-card-actions>

                        </v-card>
                    </ValidationObserver>
                </v-form>
            </v-dialog>


            <v-dialog
                persistent
                max-width="1200px"
                transition="dialog-bottom-transition"
                v-model="dialogFormCaseType"
            >
                <v-form @submit.prevent="saveCaseType">
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                        <v-card flat >
                            <v-card-text class="py-0">
                                <v-row>
                                    <v-col cols="12">
                                        <v-row class="d-flex mt-5">
                                            <v-col cols="10" class="ma-auto">
                                                <span class="title_menu">{{ $t('case_type_creation') }}</span>
                                            </v-col>
                                            <v-col cols="2" class="d-flex justify-end">
                                                <v-icon @click="closeCaseType" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-2">
                                    <v-col class="py-0" cols="12">
                                        <v-tabs v-model="tab" background-color="transparent"
                                                :right="$vuetify.breakpoint.mdAndUp"
                                                :grow="$vuetify.breakpoint.smAndDown"
                                                color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                            <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                            </v-tab>
                                        </v-tabs>
                                    </v-col>
                                </v-row>

                                <v-row class="mt-5">
                                    <v-col class="py-0" cols="12" >
                                        <ValidationProvider ref="case_type_name" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="case_type_name" type="text" :error="!valid" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('case_type_name')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable>>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>


                            </v-card-text>

                            <v-card-actions class="px-4 py-7">
                                <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                    {{ $t('save') }}
                                </v-btn>
                                <v-btn
                                    :disabled="loading"
                                    class="ma-1 button_cancel"
                                    plain
                                    @click="closeCaseType()"
                                >
                                    {{$t('cancel')}}
                                </v-btn>
                                <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                                <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                                   :active="loading" class="mx-2"></v-progress-linear>

                            </v-card-actions>

                        </v-card>
                    </ValidationObserver>
                </v-form>
            </v-dialog>
        </template>
    </PageLayout>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import debounce from "lodash/debounce";
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import Btn from "@/components/Form/Btn.vue";
    import UserSelection from "@/components/Form/UserSelection.vue";

    export default {
        name: 'CalendarShow',
        components: {
            PageLayout,
            ValidationProvider,
            ValidationObserver,
            Btn,
            UserSelection
        },
        inject: ['forceRerender'],
        data() {
            return {
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,
                calendarEvents: [],
                weekday: [1, 2, 3, 4, 5, 6, 0],
                to_do_calendar:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                filteredEvents: [],
                case_admin: [],
                showMenu: false,
                menuX: 0,
                menuY: 0,
                dialogCase: false,
                case_type_filter: null,
                case_type: null,
                caseTypeItems: [],
                caseTypeFilters: [],
                case_status: null,
                caseStatusItems: [],
                funnel_status: null,
                funnelStatusItems: [],
                date_case: null,
                dateDialogCase: false,
                on_time : null,
                onTimeMenu: false,
                case_admin_id: null,
                dialogCaseResult:false,
                case_text: null,
                case_text_result: null,
                result:false,
                selectedFiles: [],
                case_files:[],
                task_uuid: null,
                uved: null,
                uvedItems: [],
                uvedSearching: null,
                loadingUveds: false,
                deal: null,
                dealItems: [],
                dealSearching: null,
                loadingDeals: false,
                task: null,
                taskItems: [],
                taskSearching: null,
                loadingTasks: false,
                edit_case: false,
                deliveredItems:[
                    {
                        'id': -1,
                        'name': this.$t('no')
                    },
                    {
                        'id': 1,
                        'name': this.$t('yes')
                    },
                ],
                Additional: [],
                group1: true,
                group2: true,
                filter_show: false,
                date: null,
                dateDialog: false,
                sortBy: "id",
                sortDir: false,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 7,
                totalInvoices: 0,
                options: {},
                invoice_status: null,
                invoice_statusItems: [],
                document,
                date_left_calendar: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                arrayEvents: [],
                type_calendar: "month",
                currentDate: new Date().toISOString().split('T')[0],
                pickerDate: null,
                uved_info: null,
                name: null,
                responsible: null,


                participant: null,
                updateKey: 0,


                time_from : null,
                TimeFromMenu: false,
                time_to : null,
                TimeToMenu: false,
                location_event: null,
                locationEventItems: [],
                menu: false,
                reminderItems: [],
                selectedReminderItems: [],

                location_event_name: null,
                dialogFormLocationEvent: false,

                exist_translations: {},
                all_translations: true,

                dialogFormCaseType: false,
                case_type_name: null,

            }
        },
        computed: {
            ...mapGetters(['userBlock','listLanguages', 'lang', 'timezone','perPageItems']),
            languages() {
                return this.listLanguages
            },
        },
        async mounted() {
            this.language = this.languages[this.tab]
            await this.getCaseType()
            await this.getCaseStatus()
            await this.getFunnelStatus()
            await this.getLocationEvent()
            await this.getReminder()
            if (this.$refs.calendar) {
                await this.getCaseAdmin();
            }
          //  await this.getCaseAdmin()
        },
        watch: {
            pickerDate(newval, oldval) {
                this.setMonthDatePicker(newval);
            },
            uvedSearching: debounce(function (val) {
                if (val && !this.uved) {
                    this.getUveds(val)
                }
            }, 500),
            dealSearching: debounce(function (val) {
                if (val) {
                    this.getDeals(val)
                }
            }, 500),
            taskSearching: debounce(function (val) {
                if (val) {
                    this.getTasks(val)
                }
            }, 500),

        },

        methods: {
            setLanguage(val) {
                this.language = this.languages[val]
            },
            closeCaseDialog(){
                this.dialogCase = false
                this.case_text = null
                this.case_text_result = null
                this.selectedFiles = []
                this.case_status = null
                this.case_type = null
                this.name = null
                this.responsible = null
                this.participant = null
                this.time_from = null
                this.time_to = null
                this.location_event = null
                this.location_event_name = null
                this.case_type_name = null
                this.selectedReminderItems = []
                this.updateKey+= 1;
            },
            sendRoute(item){
                this.case_type_filter = item.id;
                this.getCaseAdmin();
            },
            handleFileUpload() {
                let i = this.selectedFiles.length
                let data = {}
                this.case_files.forEach((file) => {
                    data = {}
                    data.id = i
                    data.url = URL.createObjectURL(file)
                    data.name = file.name
                    data.blob =  file
                    data.size = file.size
                    data.type = file.type
                    this.selectedFiles.push(data)
                    i++
                })

            },
            deleteCaseFile(item){
                let index =  this.selectedFiles.indexOf(item)
                this.selectedFiles.splice(index, 1)
                if(this.selectedFiles.length === 0){
                    this.selectedFiles=[];

                }
            },

            setCalendarEvents() {
                this.filteredEvents = this.case_admin.filter(event => event.date_from === this.to_do_calendar);
            },
            async showEvent({ nativeEvent, event }){
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/case_admin/${event.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.case_admin_id = res.body.data.id
                        this.date_case = res.body.data.expiry_date
                        this.time_from = res.body.data.time_from
                        this.time_to = res.body.data.time_to
                        this.case_type = res.body.data.case_type.id
                        this.responsible = res.body.data.responsible
                        this.location_event = res.body.data.location_event.id
                        this.case_status = (res.body.data.case_status && res.body.data.case_status.id) ? res.body.data.case_status.id : null
                        this.case_text = res.body.data.text
                        this.name = res.body.data.name
                        this.selectedReminderItems = res.body.data.reminders
                        this.participant = res.body.data.participants
                        this.updateKey+= 1;
                        this.selectedReminderItems.forEach((item) => {
                            const index = this.reminderItems.findIndex(reminderItem => reminderItem.id === item.id);
                            if (index === -1) {
                                item.disable = true;
                            } else {
                                this.reminderItems[index].disable = item.disable;
                            }
                        });




                        this.on_time = res.body.data.expiry_time
                        this.case_statusfunnel_status = (res.body.data.funnel_status && res.body.data.funnel_status.id) ? res.body.data.funnel_status.id : null

                        this.case_text_result = res.body.data.result_text
                        this.uved_info = res.body.data.uved  ? res.body.data.uved : null
                        this.uved = (res.body.data.uved && res.body.data.uved.id) ? res.body.data.uved.id : null
                        this.uvedItems = [res.body.data.uved]
                        this.deal = (res.body.data.deal && res.body.data.deal.id) ? res.body.data.deal.id : null
                        this.dealItems = [res.body.data.deal]
                        this.task = (res.body.data.task && res.body.data.task.id) ? res.body.data.task.id : null
                        this.taskItems = [res.body.data.task]
                        if(res.body.data.case_status && res.body.data.case_status.id){
                            this.selectedFiles = [];
                            res.body.data.documents.forEach((file) => {
                                this.selectedFiles.push(file)
                            })
                            this.dialogCaseResult = true;
                        }
                        else {
                            this.createEvent(false)
                        }
                        this.edit_case = true;

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_case_admin'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
            viewDay ({ date }) {
                this.focus = date
                this.type_calendar = 'day'
            },
            async setMonthDatePicker(event){
                this.type_calendar = 'month'
                this.to_do_calendar = event+ '-01';
                let currentDate = new Date(this.to_do_calendar);
                let lastDayOfMonth = this.$moment(currentDate).clone().endOf('month');
                this.$refs.calendar.lastStart.date = this.to_do_calendar
                this.$refs.calendar.lastEnd.date = lastDayOfMonth.format('YYYY-MM-DD')
                await this.getCaseAdmin()

            },

            setDateLeftCalendar(event){
                this.type_calendar = 'day'
                this.to_do_calendar = event;
                this.setCalendarEvents()
            },
            showContextMenu(date, event) {
                event.preventDefault()
                this.menuX = event.clientX;
                this.menuY = event.clientY;
                this.date_case = date.date
                this.on_time = date.time
                this.updateDisableStatus();
                this.showMenu = true;
                document.addEventListener('click', this.closeContextMenu);
            },
            createEvent(create) {
                if(create){
                    this.case_admin_id = null
                    this.case_type = null
                    this.uvedItems = []
                    this.uved = null
                    this.dealItems = []
                    this.deal = null
                    this.taskItems = []
                    this.task = null
                    this.edit_case = false

                }
                this.result = false
                this.dialogCase = true
                this.closeContextMenu();
            },
            updateDisableStatus() {
                let futureDateTimeString = this.date_case;
                if(this.time_from){
                    futureDateTimeString = `${this.date_case}T${this.time_from}:00`;
                }
                const currentDate = new Date();
                const futureDate = new Date(futureDateTimeString);
                // Вычисление разницы в минутах
                const differenceInMinutes = Math.floor((futureDate - currentDate) / (1000 * 60));
                this.reminderItems.forEach(item => {
                    item.disable = item.minutes >= differenceInMinutes;
                });
            },
            deleteEvent() {
                this.closeContextMenu();
            },
            closeContextMenu() {
                this.showMenu = false;
                document.removeEventListener('click', this.closeContextMenu);
            },
            formResult(){
                this.case_status = null;
                this.case_text_result = null;
                this.selectedFiles = [];
                this.result = true;
            },
            async addCaseAdmin() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()



                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.case_type) {
                    formData.append('case_type', this.case_type)
                }
                if (this.name) {
                    formData.append('name', this.name)
                }
                if (this.case_text) {
                    formData.append('case_text', this.case_text)
                }

                if (this.responsible && this.responsible.id) {
                    formData.append('responsible', this.responsible.id)
                }

                if (this.responsible && this.responsible.length > 0) {
                    for (let i in this.responsible) {
                        if (this.responsible[i].id !== undefined && this.responsible[i].id > 0) {
                            formData.append(`responsibles[${i}]`, this.responsible[i].id)
                        }
                    }
                }
                if (this.participant && this.participant.length > 0) {
                    for (let i in this.participant) {
                        if (this.participant[i].id !== undefined && this.participant[i].id > 0) {
                            formData.append(`participants[${i}]`, this.participant[i].id)
                        }
                    }
                }
                if (this.date_case) {
                    formData.append('timezone', this.date_case)
                }
                if (this.time_from) {
                    formData.append('time_from', this.time_from)
                }
                if (this.time_to) {
                    formData.append('time_to', this.time_to)
                }
                if (this.location_event) {
                    formData.append('location_event', this.location_event)
                }

                if (this.selectedReminderItems && this.selectedReminderItems.length > 0) {
                    for (let i in this.selectedReminderItems) {
                        if (this.selectedReminderItems[i].id !== undefined && this.selectedReminderItems[i].id > 0) {
                            formData.append(`reminders[${i}]`, this.selectedReminderItems[i].id)
                        }
                    }
                }


                if (this.case_status) {
                    formData.append('case_status', this.case_status)
                }
                if (this.funnel_status) {
                    formData.append('funnel_status', this.funnel_status)
                }

                if(this.$route.params.id) {
                    formData.append('task_uuid', this.$route.params.id)
                }

                if(this.$auth.user().id) {
                    formData.append('admin', this.$auth.user().id)
                }






                if (this.case_text_result) {
                    formData.append('case_text_result', this.case_text_result)
                }

                if (this.uved) {
                    if (this.uved.id) {
                        formData.append('uved', this.uved.id)
                    } else {
                        formData.append('uved', this.uved)
                    }
                }

                if (this.deal) {
                    if (this.deal.id) {
                        formData.append('deal', this.deal.id)
                    } else {
                        formData.append('deal', this.deal)
                    }
                }

                if (this.task) {
                    if (this.task.id) {
                        formData.append('task', this.task.id)
                    } else {
                        formData.append('task', this.task)
                    }
                }
                formData.append('id_array', 1);





                if (this.selectedFiles && this.selectedFiles.length > 0) {
                    let is_image = false;
                    for (let i in this.selectedFiles) {
                        formData.append(`type_mime[${i}]`, this.selectedFiles[i].type)
                        formData.append(`size[${i}]`, this.selectedFiles[i].size)
                        formData.append(`file_name[${i}]`, this.selectedFiles[i].name)
                        formData.append(`is_image[${i}]`, is_image ? 1 : 0)

                        this.selectedFiles[i].blob =  await this.convertBlobToBase64(this.selectedFiles[i].blob)
                        if (this.selectedFiles[i].blob) {
                            let blob = this.dataURL64toBlob(this.selectedFiles[i].blob)
                            if (blob) {
                                formData.append(`upfiles[${i}]`, blob, this.selectedFiles[i].type)
                            }
                        }

                        // formData.append(`upfiles[${i}]`, this.selectedFiles[i].blob)
                    }
                }



                if (this.case_admin_id ) {
                    await this.$http
                        .put(`admin/case_admin/${this.case_admin_id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('case_admin_has_been_updated'))
                            this.dialogCase = false
                            this.getCaseAdmin()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('case_admin_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/case_admin', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('case_admin_has_been_added'))
                            this.dialogCase = false
                            this.getCaseAdmin()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('case_admin_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            async getCaseAdmin() {

                this.loading = true
                let params = {}

                if(this.$route.params.id) {
                    params.task_uuid = this.$route.params.id
                }

                if(this.case_type_filter) {
                    params.case_type = this.case_type_filter
                }

                if(this.$auth.user().id) {
                    params.admin = this.$auth.user().id
                }

                if(this.$refs.calendar === undefined){
                    const currentDate = this.$moment();
                    const firstDayOfMonth = currentDate.clone().startOf('month');
                    const lastDayOfMonth = currentDate.clone().endOf('month');
                    params.date_start = firstDayOfMonth.format('YYYY-MM-DD');
                    params.date_end = lastDayOfMonth.format('YYYY-MM-DD');
                }
                else{
                    params.date_start = this.$refs.calendar.lastStart.date;
                    params.date_end = this.$refs.calendar.lastEnd.date;
                }


                await this.$http
                    .get("admin/case_admin", {
                        params: params,
                    })
                    .then(res => {
                        this.calendarEvents = [];
                        this.arrayEvents = [];
                        this.case_admin = res.body.data
                        let to_do = this.$moment(new Date()).format('YYYY-MM-DD')
                        for (let i = 0; i < this.case_admin.length; i++) {
                            this.calendarEvents.push(
                                {
                                    id: this.case_admin[i].id,
                                    name: this.case_admin[i].title,
                                    start: this.case_admin[i].start,
                                    color: this.case_admin[i].case_status_id > 0 ? 'green' : (to_do > this.case_admin[i].expiry_date ? 'red' : 'blue'),
                                    timed: true,
                                    uved: this.case_admin[i].uved,
                                  //  end: this.case_admin[i].end,
                                }

                            );
                            this.arrayEvents.push(this.case_admin[i].expiry_date)
                        }
                    })
                    .catch(err => {
                        this.case_admin = []
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },
            async calendarPrev(){
                this.calendarEvents = []
                await this.$refs.calendar.prev();
                await this.getCaseAdmin();
            },
            async calendarNext(){
                this.calendarEvents = []
                await this.$refs.calendar.next();
                await this.getCaseAdmin();
            },

            async getCaseType() {
                this.loading = true
                let params = {}
                params.for_admin = 1;
                await this.$http
                    .get("admin/case_type", {
                        params: params,
                    })
                    .then(res => {
                        this.caseTypeItems = res.body.data
                        let caseTypeFilters = Object.assign([], res.body.data);
                        caseTypeFilters.unshift({"name": this.$t('all_case'), "id":null})
                        this.caseTypeFilters = caseTypeFilters;
                    })
                    .catch(err => {
                        this.caseTypeItems = []
                        this.caseTypeFilters = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getCaseStatus() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/case_status", {
                        params: params,
                    })
                    .then(res => {
                        this.caseStatusItems = res.body.data
                    })
                    .catch(err => {
                        this.caseStatusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getFunnelStatus() {
                this.loading = true
                let params = {}
                params.filter = 'select'
                params.no_main = 1
                await this.$http
                    .get("admin/funnel_status", {
                        params: params,
                    })
                    .then(res => {
                        this.funnelStatusItems = res.body.data
                    })
                    .catch(err => {
                        this.funnelStatusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getLocationEvent() {
                this.loading = true
                let params = {}
                params.for_admin = 1
                await this.$http
                    .get("admin/location_event", {
                        params: params,
                    })
                    .then(res => {
                        this.locationEventItems = res.body.data
                    })
                    .catch(err => {
                        this.locationEventItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getReminder() {
                this.loading = true
                let params = {}
                params.no_main = 1
                await this.$http
                    .get("admin/reminder_times", {
                        params: params,
                    })
                    .then(res => {
                        this.reminderItems = res.body.data
                    })
                    .catch(err => {
                        this.reminderItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            clearUveds() {
                if (!this.uved) {
                    this.uvedItems = []
                }
            },
            redirectToUved(val) {
                if (val) {
                    this.$router.push({
                        name: 'uved.edit',
                        params: {
                            id: val
                        }
                    })
                }
            },
            async getUveds(str) {
                if (str) {
                    this.loadingUveds = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.uved = str
                    }
                    await this.$http
                        .get("admin/uved", {
                            params: params,
                        })
                        .then(res => {
                            this.uvedItems = res.body.data
                        })
                        .catch(err => {
                            this.uvedItems = []
                        })
                        .finally(end => {
                            this.loadingUveds = false
                            this.$nextTick(() => {
                                if (this.$refs.uved) {
                                    this.$refs.uved.$el.querySelector('input').focus();
                                }
                            });
                        })
                }
            },
            async getDeals(str) {
                this.loadingDeal = true
                let params = {};
                params.filter = 'not_topic'
                if (str !== 'undefined') {
                    params.number = str;
                }

                await this.$http
                    .get("admin/deal", {
                        params: params,
                    })
                    .then(res => {
                        this.dealItems = res.body.data;
                    })
                    .catch(err => {
                        this.dealItems = []
                        this.$toastr.error(this.$t('failed_to_get_deals'))
                    })
                    .finally(end => {
                        this.loadingDeal = false
                    })
            },
            clearDeals() {
                if (!this.deal) {
                    this.dealItems = []
                }
            },
            async getTasks(str) {
                this.loadingTasks = true
                let params = {};
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.number = str;
                }

                await this.$http
                    .get("admin/task", {
                        params: params,
                    })
                    .then(res => {
                        this.taskItems = res.body.data;
                    })
                    .catch(err => {
                        this.tasktems = []
                        this.$toastr.error(this.$t('failed_to_get_tasks'))
                    })
                    .finally(end => {
                        this.loadingTasks = false
                    })
            },
            clearTasks() {
                if (!this.task) {
                    this.taskItems = []
                }
            },
            downloadFile(data) {
                fetch(data.url)
                    .then((resp) => resp.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = data.name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.$toastr.success(this.$t('file_download_started'))
                    })
                    .catch(() =>
                        this.$toastr.error(this.$t('file_download_error'))

                    );
            },



            selectItem(item) {
                const index = this.selectedReminderItems.indexOf(item);
                if (index === -1) {
                    item.disable = true
                    this.selectedReminderItems.push(item);
                }
                this.menu = false;
            },
            deleteItem(item) {
                const index = this.selectedReminderItems.indexOf(item);
                if (index > -1) {
                    item.disable = false
                    this.selectedReminderItems.splice(index, 1);
                }
                this.menu = false;
            },

            async saveLocationEvent() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)
                }
                if (this.location_event_name) {
                    formData.append('location_event', this.location_event_name)
                }
                formData.append('for_admin', 1)
                // Add
                await this.$http
                    .post('admin/location_event', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('location_event_has_been_added'))
                        this.getLocationEvent()
                        this.closeLocationEvent();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('location_event_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
            closeLocationEvent(){
                this.dialogFormLocationEvent = false;
                this.location_event_name = null;

            },

            async saveCaseType() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)
                }
                if (this.case_type_name) {
                    formData.append('case_type', this.case_type_name)

                }
                formData.append('for_admin', 1)
                // Add
                await this.$http
                    .post('admin/case_type', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('case_type_has_been_added'))
                        this.getCaseType()
                        this.closeCaseType();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('case_type_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
            closeCaseType(){
                this.dialogFormCaseType = false;
                this.case_type_name = null;

            }





        }
    }
</script>

<style lang="scss">
.calendar-layout {
    display: flex;
    flex-wrap: wrap;
    margin-left: $grid-gutter / -2;
    margin-right: $grid-gutter / -2;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        height: var(--header-height);
    }
}

.calendar-layout__left,
.calendar-layout__right {
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.calendar-layout__right {
    flex-grow: 1;
}
</style>
